import React from "react";
import { graphql, navigate, useStaticQuery } from "gatsby";
import moment from "moment";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const BlogHomeFooter = () => {
  const data = useStaticQuery(graphql`
    {
      allGraphCmsPost(limit: 4, sort: { fields: publishedAt, order: DESC }) {
        edges {
          node {
            title
            slug
            date
            publishedAt
            originalImage: coverImage {
              fileName
            }
            coverImage {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  `);

  const posts = data?.allGraphCmsPost?.edges?.map((edge) => {
    return edge?.node;
  });

  return (
    <div className="py-10  px-5 sm:px-6 md:max-lg:px-0 xl:px-40 md:px-8 mx-auto md:flex flex-col items-center">
      <div className="w-full xl:w-[1200px]">
        <div
          role="heading"
          className="flex justify-start text-2xl font-avenir800 md:text-[32px]  md:flex md:justify-center md:pt-2 md:pb-4"
        >
          Check out detailed care guides and articles!
        </div>

        <div className="w-full grid grid-flow-col gap-x-4 flex-nowrap overflow-scroll py-3 md:overflow-hidden md:grid-cols-4  md:py-5 md:whitespace-pre-wrap ">
          {posts?.map((post, index) => {
            const blogCoverImage = getImage(post?.coverImage);
            return (
              <div
                tabIndex="0"
                role="button"
                className="mx-2 cursor-pointer w-full md:mx-0"
                key={index}
                onClick={() => navigate(`/blogs/${post?.slug?.toLowerCase()}/`)}
              >
                <GatsbyImage
                  tabIndex="0"
                  image={blogCoverImage}
                  className="w-64 h-40 md:w-full md:h-40 rounded-xl object-cover hover:shadow-xl"
                  alt={post?.originalImage?.fileName || "blog-feature-img"}
                />
                <p
                  tabIndex="0"
                  className="underline underline-offset-4 font-avenir800  text-base md:text-xl mt-3 "
                >
                  {post?.title}
                </p>
                <p
                  tabIndex="0"
                  className="text-slate-500 font-semibold mt-3 text-base md:text-lg"
                >
                  {moment(post?.publishedAt).format("MMM DD, YYYY")}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BlogHomeFooter;
